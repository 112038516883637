import { goto } from '$app/navigation'
import { TravelSolution } from '$lib/gen/travelsolution/v1/travelsolution_pb'
import type { Message, PartialMessage } from '@bufbuild/protobuf'
import { decodeTravelSolution, defaultTravelSolution, encode } from './encoding'
import { mergePartial } from './protomerge/reflection-merge-partial'

export const SOLUTION_PARAM = 'ts'

export type GotoOptions = {
  replaceState?: boolean
  noScroll?: boolean
  keepFocus?: boolean
  state?: unknown
  invalidateAll?: boolean
}

export function buildSolutionURL(solution: Message, nextURL: string | URL): URL {
  const solutionStr = encode(solution)
  const newURL = new URL(nextURL)
  newURL.searchParams.set(SOLUTION_PARAM, solutionStr)
  return newURL
}

export function buildURLWithEncodedSolution(solutionStr: string, nextURL: string | URL): URL {
  const newURL = new URL(nextURL)
  newURL.searchParams.set(SOLUTION_PARAM, solutionStr)
  return newURL
}

export async function gotoSolution(
  solution: Message,
  currentURL: string | URL,
  options?: GotoOptions,
): Promise<void> {
  const newURL = buildSolutionURL(solution, currentURL)
  if (!solutionDidChange(newURL, new URL(currentURL))) {
    return
  }

  goto(newURL.toString(), { keepFocus: true, noScroll: true, ...options })
}

function solutionDidChange(a: URL, b: URL): boolean {
  if (a == null || b == null) return false

  const aSolution = a.searchParams.get(SOLUTION_PARAM)
  const bSolution = b.searchParams.get(SOLUTION_PARAM)
  return aSolution !== bSolution
}

export function travelSolutionFromURL(url: string | URL): TravelSolution {
  const urlObj = new URL(url)
  if (urlObj.searchParams.has(SOLUTION_PARAM)) {
    const encoded = urlObj.searchParams.get(SOLUTION_PARAM)
    if (encoded.length > 0) {
      return decodeTravelSolution(encoded)
    }
  }
  return defaultTravelSolution()
}

// @deprecated use solution.update(...) instead
export async function updateSolution(
  currentURL: URL,
  source: PartialMessage<TravelSolution>,
  opts?: GotoOptions,
) {
  let target = travelSolutionFromURL(currentURL)
  if (source) {
    mergePartial(target, source, true)
    target = target.clone()
  }
  return gotoSolution(target, currentURL, opts)
}

export function stageSolution(
  target: TravelSolution,
  source: PartialMessage<TravelSolution>,
): TravelSolution {
  if (target) {
    mergePartial(target, source, true)
    target = target.clone()
  }
  return target
}

// @deprecated use solution.update(...) instead
export function updateSolutionURL(currentURL: URL, source?: PartialMessage<TravelSolution>) {
  let target = travelSolutionFromURL(currentURL)
  if (source) {
    mergePartial(target, source, true)
    target = target.clone()
  }
  return buildSolutionURL(target, currentURL)
}
