import {
  ScalarType,
  type AnyMessage,
  type FieldInfo,
  type Message,
  type PartialMessage,
} from '@bufbuild/protobuf'

/**
 * Copy partial data into the target message.
 *
 * Replaces fields in the target with the fields from the
 * (partial) source.
 *
 * Omitted fields are not replaced.
 * Copies all values.
 * A default value in the source will replace a value in the target.
 *
 * Message fields are recursively merged (by calling `mergePartial()`
 * of the responsible message handler). Map and repeated fields
 * are simply overwritten, not appended or merged.
 */
export function mergePartial<T extends Message<T>>(
  target: T,
  source: PartialMessage<T>,
  useDefaults?: boolean,
): void {
  if (source === undefined) {
    return
  }
  const type = target.getType()
  for (const member of type.fields.byMember()) {
    const localName = member.localName,
      t = target as AnyMessage,
      s = source as PartialMessage<AnyMessage>
    if (s[localName] === undefined) {
      continue
    }

    switch (member.kind) {
      case 'oneof':
        const sk = s[localName].case
        if (sk === undefined) {
          continue
        }
        const sourceField = member.findField(sk)
        let val = s[localName].value
        if (sourceField && sourceField.kind == 'message' && !(val instanceof sourceField.T)) {
          val = new sourceField.T(val)
        }
        t[localName] = { case: sk, value: val }
        break
      case 'scalar':
      case 'enum':
        let isZero = isZeroScalarValue(member, s)
        if (useDefaults && isZero) {
          isZero = false
        }
        if (!isZero) {
          t[localName] = s[localName]
        }
        break

      case 'map':
        switch (member.V.kind) {
          case 'scalar':
          case 'enum':
            Object.assign(t[localName], s[localName])
            break
          case 'message':
            const messageType = member.V.T
            for (const k of Object.keys(s[localName])) {
              let val = s[localName][k]
              if (!messageType.fieldWrapper) {
                // We only take partial input for messages that are not a wrapper type.
                // For those messages, we recursively normalize the partial input.
                val = new messageType(val)
              }
              t[localName][k] = val
            }
            break
        }
        break

      case 'message':
        const mt = member.T
        if (member.repeated) {
          t[localName] = (s[localName] as any[]).map((val) => {
            let newVal = val instanceof mt ? val : new mt(val)
            mergePartial(newVal, val, useDefaults)
            return newVal
          })
        } else if (s[localName] === undefined) {
          t[localName] = new mt() // nothing to merge with
        } else {
          const val = s[localName]
          mergePartial(t[localName], val, useDefaults)
        }
        break
    }
  }
}

function isZeroScalarValue(member: FieldInfo, s: PartialMessage<AnyMessage>) {
  let isZero = false
  let localName = member.localName
  if (member.kind != 'scalar') {
    return false
  }

  switch (member.T) {
    case ScalarType.STRING:
      isZero = s[localName] === ''
      break
    case ScalarType.BYTES:
      isZero = s[localName].length === 0
      break
    case ScalarType.BOOL:
      isZero = s[localName] === false
      break
    case ScalarType.INT32:
    case ScalarType.INT64:
    case ScalarType.UINT32:
    case ScalarType.UINT64:
    case ScalarType.SINT32:
    case ScalarType.SINT64:
    case ScalarType.FIXED32:
    case ScalarType.FIXED64:
    case ScalarType.SFIXED32:
    case ScalarType.SFIXED64:
    case ScalarType.FLOAT:
    case ScalarType.DOUBLE:
      isZero = s[localName] === 0
      break
  }

  return isZero
}
