<script lang="ts">
  import { formatAmount, type AmountLike } from '$lib/utils/currency'

  export let loading = false
  export let rounded = false
  export let amount: AmountLike
  export let options: Intl.NumberFormatOptions = undefined

  let className = ''
  export { className as class }

  $: formattedAmount = loading
    ? '123.00'
    : formatAmount(amount?.amount, amount?.currency, rounded, options)
</script>

<span data-testid="amount" class={`${className} ${loading ? 'blur-sm' : ''}`}
  >{formattedAmount}</span
>
