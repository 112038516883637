import { TravelSolution } from '$lib/gen/travelsolution/v1/travelsolution_pb'
import type { Message } from '@bufbuild/protobuf'
import { addDays, nextFriday, nextSunday } from 'date-fns'
import { Base64 } from 'js-base64'
import { toPBDate } from './date'

export function encode(msg: Message): string {
  if (msg == null) msg = new TravelSolution()
  const bin = msg.toBinary()
  return Base64.fromUint8Array(bin, true)
}

export function decodeTravelSolution(str: string): TravelSolution {
  try {
    if (str === '' || str == null) return new TravelSolution()
    const bin = Base64.toUint8Array(str)
    return TravelSolution.fromBinary(bin, { readUnknownFields: false })
  } catch (e) {
    return defaultTravelSolution()
  }
}

export function findIdealWeekend() {
  const newDate = new Date()
  newDate.setDate(newDate.getDate() + 1)
  const now = newDate
  const start = addDays(now, 10)
  const friday = nextFriday(start)
  const sunday = nextSunday(friday)
  return [friday, sunday]
}

export function defaultTravelSolution(): TravelSolution {
  const [checkIn, checkOut] = findIdealWeekend()

  return new TravelSolution({
    hotelQuery: {
      adultCount: 2,
      childAges: [],
      checkIn: toPBDate(checkIn),
      checkOut: toPBDate(checkOut),
      locationScopeId: '',
    },
  })
}
