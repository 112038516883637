// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file common/v1/currency.proto (package common.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {proto3} from "@bufbuild/protobuf";

/**
 * @generated from enum common.v1.Currency
 */
export enum Currency {
  /**
   * @generated from enum value: CURRENCY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CURRENCY_USD = 1;
   */
  USD = 1,

  /**
   * @generated from enum value: CURRENCY_AED = 2;
   */
  AED = 2,

  /**
   * @generated from enum value: CURRENCY_AFN = 3;
   */
  AFN = 3,

  /**
   * @generated from enum value: CURRENCY_ALL = 4;
   */
  ALL = 4,

  /**
   * @generated from enum value: CURRENCY_AMD = 5;
   */
  AMD = 5,

  /**
   * @generated from enum value: CURRENCY_ANG = 6;
   */
  ANG = 6,

  /**
   * @generated from enum value: CURRENCY_AOA = 7;
   */
  AOA = 7,

  /**
   * @generated from enum value: CURRENCY_ARS = 8;
   */
  ARS = 8,

  /**
   * @generated from enum value: CURRENCY_AUD = 9;
   */
  AUD = 9,

  /**
   * @generated from enum value: CURRENCY_AWG = 10;
   */
  AWG = 10,

  /**
   * @generated from enum value: CURRENCY_AZN = 11;
   */
  AZN = 11,

  /**
   * @generated from enum value: CURRENCY_BAM = 12;
   */
  BAM = 12,

  /**
   * @generated from enum value: CURRENCY_BBD = 13;
   */
  BBD = 13,

  /**
   * @generated from enum value: CURRENCY_BDT = 14;
   */
  BDT = 14,

  /**
   * @generated from enum value: CURRENCY_BGN = 15;
   */
  BGN = 15,

  /**
   * @generated from enum value: CURRENCY_BIF = 16;
   */
  BIF = 16,

  /**
   * @generated from enum value: CURRENCY_BMD = 17;
   */
  BMD = 17,

  /**
   * @generated from enum value: CURRENCY_BND = 18;
   */
  BND = 18,

  /**
   * @generated from enum value: CURRENCY_BOB = 19;
   */
  BOB = 19,

  /**
   * @generated from enum value: CURRENCY_BRL = 20;
   */
  BRL = 20,

  /**
   * @generated from enum value: CURRENCY_BSD = 21;
   */
  BSD = 21,

  /**
   * @generated from enum value: CURRENCY_BWP = 22;
   */
  BWP = 22,

  /**
   * @generated from enum value: CURRENCY_BYN = 23;
   */
  BYN = 23,

  /**
   * @generated from enum value: CURRENCY_BZD = 24;
   */
  BZD = 24,

  /**
   * @generated from enum value: CURRENCY_CAD = 25;
   */
  CAD = 25,

  /**
   * @generated from enum value: CURRENCY_CDF = 26;
   */
  CDF = 26,

  /**
   * @generated from enum value: CURRENCY_CHF = 27;
   */
  CHF = 27,

  /**
   * @generated from enum value: CURRENCY_CLP = 28;
   */
  CLP = 28,

  /**
   * @generated from enum value: CURRENCY_CNY = 29;
   */
  CNY = 29,

  /**
   * @generated from enum value: CURRENCY_COP = 30;
   */
  COP = 30,

  /**
   * @generated from enum value: CURRENCY_CRC = 31;
   */
  CRC = 31,

  /**
   * @generated from enum value: CURRENCY_CVE = 32;
   */
  CVE = 32,

  /**
   * @generated from enum value: CURRENCY_CZK = 33;
   */
  CZK = 33,

  /**
   * @generated from enum value: CURRENCY_DJF = 34;
   */
  DJF = 34,

  /**
   * @generated from enum value: CURRENCY_DKK = 35;
   */
  DKK = 35,

  /**
   * @generated from enum value: CURRENCY_DOP = 36;
   */
  DOP = 36,

  /**
   * @generated from enum value: CURRENCY_DZD = 37;
   */
  DZD = 37,

  /**
   * @generated from enum value: CURRENCY_EGP = 38;
   */
  EGP = 38,

  /**
   * @generated from enum value: CURRENCY_ETB = 39;
   */
  ETB = 39,

  /**
   * @generated from enum value: CURRENCY_EUR = 40;
   */
  EUR = 40,

  /**
   * @generated from enum value: CURRENCY_FJD = 41;
   */
  FJD = 41,

  /**
   * @generated from enum value: CURRENCY_FKP = 42;
   */
  FKP = 42,

  /**
   * @generated from enum value: CURRENCY_GBP = 43;
   */
  GBP = 43,

  /**
   * @generated from enum value: CURRENCY_GEL = 44;
   */
  GEL = 44,

  /**
   * @generated from enum value: CURRENCY_GIP = 45;
   */
  GIP = 45,

  /**
   * @generated from enum value: CURRENCY_GMD = 46;
   */
  GMD = 46,

  /**
   * @generated from enum value: CURRENCY_GNF = 47;
   */
  GNF = 47,

  /**
   * @generated from enum value: CURRENCY_GTQ = 48;
   */
  GTQ = 48,

  /**
   * @generated from enum value: CURRENCY_GYD = 49;
   */
  GYD = 49,

  /**
   * @generated from enum value: CURRENCY_HKD = 50;
   */
  HKD = 50,

  /**
   * @generated from enum value: CURRENCY_HNL = 51;
   */
  HNL = 51,

  /**
   * @generated from enum value: CURRENCY_HRK = 52;
   */
  HRK = 52,

  /**
   * @generated from enum value: CURRENCY_HTG = 53;
   */
  HTG = 53,

  /**
   * @generated from enum value: CURRENCY_HUF = 54;
   */
  HUF = 54,

  /**
   * @generated from enum value: CURRENCY_IDR = 55;
   */
  IDR = 55,

  /**
   * @generated from enum value: CURRENCY_ILS = 56;
   */
  ILS = 56,

  /**
   * @generated from enum value: CURRENCY_INR = 57;
   */
  INR = 57,

  /**
   * @generated from enum value: CURRENCY_ISK = 58;
   */
  ISK = 58,

  /**
   * @generated from enum value: CURRENCY_JMD = 59;
   */
  JMD = 59,

  /**
   * @generated from enum value: CURRENCY_JPY = 60;
   */
  JPY = 60,

  /**
   * @generated from enum value: CURRENCY_KES = 61;
   */
  KES = 61,

  /**
   * @generated from enum value: CURRENCY_KGS = 62;
   */
  KGS = 62,

  /**
   * @generated from enum value: CURRENCY_KHR = 63;
   */
  KHR = 63,

  /**
   * @generated from enum value: CURRENCY_KMF = 64;
   */
  KMF = 64,

  /**
   * @generated from enum value: CURRENCY_KRW = 65;
   */
  KRW = 65,

  /**
   * @generated from enum value: CURRENCY_KYD = 66;
   */
  KYD = 66,

  /**
   * @generated from enum value: CURRENCY_KZT = 67;
   */
  KZT = 67,

  /**
   * @generated from enum value: CURRENCY_LAK = 68;
   */
  LAK = 68,

  /**
   * @generated from enum value: CURRENCY_LBP = 69;
   */
  LBP = 69,

  /**
   * @generated from enum value: CURRENCY_LKR = 70;
   */
  LKR = 70,

  /**
   * @generated from enum value: CURRENCY_LRD = 71;
   */
  LRD = 71,

  /**
   * @generated from enum value: CURRENCY_LSL = 72;
   */
  LSL = 72,

  /**
   * @generated from enum value: CURRENCY_MAD = 73;
   */
  MAD = 73,

  /**
   * @generated from enum value: CURRENCY_MDL = 74;
   */
  MDL = 74,

  /**
   * @generated from enum value: CURRENCY_MGA = 75;
   */
  MGA = 75,

  /**
   * @generated from enum value: CURRENCY_MKD = 76;
   */
  MKD = 76,

  /**
   * @generated from enum value: CURRENCY_MMK = 77;
   */
  MMK = 77,

  /**
   * @generated from enum value: CURRENCY_MNT = 78;
   */
  MNT = 78,

  /**
   * @generated from enum value: CURRENCY_MOP = 79;
   */
  MOP = 79,

  /**
   * @generated from enum value: CURRENCY_MRO = 80;
   */
  MRO = 80,

  /**
   * @generated from enum value: CURRENCY_MUR = 81;
   */
  MUR = 81,

  /**
   * @generated from enum value: CURRENCY_MVR = 82;
   */
  MVR = 82,

  /**
   * @generated from enum value: CURRENCY_MWK = 83;
   */
  MWK = 83,

  /**
   * @generated from enum value: CURRENCY_MXN = 84;
   */
  MXN = 84,

  /**
   * @generated from enum value: CURRENCY_MYR = 85;
   */
  MYR = 85,

  /**
   * @generated from enum value: CURRENCY_MZN = 86;
   */
  MZN = 86,

  /**
   * @generated from enum value: CURRENCY_NAD = 87;
   */
  NAD = 87,

  /**
   * @generated from enum value: CURRENCY_NGN = 88;
   */
  NGN = 88,

  /**
   * @generated from enum value: CURRENCY_NIO = 89;
   */
  NIO = 89,

  /**
   * @generated from enum value: CURRENCY_NOK = 90;
   */
  NOK = 90,

  /**
   * @generated from enum value: CURRENCY_NPR = 91;
   */
  NPR = 91,

  /**
   * @generated from enum value: CURRENCY_NZD = 92;
   */
  NZD = 92,

  /**
   * @generated from enum value: CURRENCY_PAB = 93;
   */
  PAB = 93,

  /**
   * @generated from enum value: CURRENCY_PEN = 94;
   */
  PEN = 94,

  /**
   * @generated from enum value: CURRENCY_PGK = 95;
   */
  PGK = 95,

  /**
   * @generated from enum value: CURRENCY_PHP = 96;
   */
  PHP = 96,

  /**
   * @generated from enum value: CURRENCY_PKR = 97;
   */
  PKR = 97,

  /**
   * @generated from enum value: CURRENCY_PLN = 98;
   */
  PLN = 98,

  /**
   * @generated from enum value: CURRENCY_PYG = 99;
   */
  PYG = 99,

  /**
   * @generated from enum value: CURRENCY_QAR = 100;
   */
  QAR = 100,

  /**
   * @generated from enum value: CURRENCY_RON = 101;
   */
  RON = 101,

  /**
   * @generated from enum value: CURRENCY_RSD = 102;
   */
  RSD = 102,

  /**
   * @generated from enum value: CURRENCY_RUB = 103;
   */
  RUB = 103,

  /**
   * @generated from enum value: CURRENCY_RWF = 104;
   */
  RWF = 104,

  /**
   * @generated from enum value: CURRENCY_SAR = 105;
   */
  SAR = 105,

  /**
   * @generated from enum value: CURRENCY_SBD = 106;
   */
  SBD = 106,

  /**
   * @generated from enum value: CURRENCY_SCR = 107;
   */
  SCR = 107,

  /**
   * @generated from enum value: CURRENCY_SEK = 108;
   */
  SEK = 108,

  /**
   * @generated from enum value: CURRENCY_SGD = 109;
   */
  SGD = 109,

  /**
   * @generated from enum value: CURRENCY_SHP = 110;
   */
  SHP = 110,

  /**
   * @generated from enum value: CURRENCY_SLE = 111;
   */
  SLE = 111,

  /**
   * @generated from enum value: CURRENCY_SLL = 112;
   */
  SLL = 112,

  /**
   * @generated from enum value: CURRENCY_SOS = 113;
   */
  SOS = 113,

  /**
   * @generated from enum value: CURRENCY_SRD = 114;
   */
  SRD = 114,

  /**
   * @generated from enum value: CURRENCY_STD = 115;
   */
  STD = 115,

  /**
   * @generated from enum value: CURRENCY_SZL = 116;
   */
  SZL = 116,

  /**
   * @generated from enum value: CURRENCY_THB = 117;
   */
  THB = 117,

  /**
   * @generated from enum value: CURRENCY_TJS = 118;
   */
  TJS = 118,

  /**
   * @generated from enum value: CURRENCY_TOP = 119;
   */
  TOP = 119,

  /**
   * @generated from enum value: CURRENCY_TRY = 120;
   */
  TRY = 120,

  /**
   * @generated from enum value: CURRENCY_TTD = 121;
   */
  TTD = 121,

  /**
   * @generated from enum value: CURRENCY_TWD = 122;
   */
  TWD = 122,

  /**
   * @generated from enum value: CURRENCY_TZS = 123;
   */
  TZS = 123,

  /**
   * @generated from enum value: CURRENCY_UAH = 124;
   */
  UAH = 124,

  /**
   * @generated from enum value: CURRENCY_UGX = 125;
   */
  UGX = 125,

  /**
   * @generated from enum value: CURRENCY_UYU = 126;
   */
  UYU = 126,

  /**
   * @generated from enum value: CURRENCY_UZS = 127;
   */
  UZS = 127,

  /**
   * @generated from enum value: CURRENCY_VND = 128;
   */
  VND = 128,

  /**
   * @generated from enum value: CURRENCY_VUV = 129;
   */
  VUV = 129,

  /**
   * @generated from enum value: CURRENCY_WST = 130;
   */
  WST = 130,

  /**
   * @generated from enum value: CURRENCY_XAF = 131;
   */
  XAF = 131,

  /**
   * @generated from enum value: CURRENCY_XCD = 132;
   */
  XCD = 132,

  /**
   * @generated from enum value: CURRENCY_XOF = 133;
   */
  XOF = 133,

  /**
   * @generated from enum value: CURRENCY_XPF = 134;
   */
  XPF = 134,

  /**
   * @generated from enum value: CURRENCY_YER = 135;
   */
  YER = 135,

  /**
   * @generated from enum value: CURRENCY_ZAR = 136;
   */
  ZAR = 136,

  /**
   * @generated from enum value: CURRENCY_ZMW = 137;
   */
  ZMW = 137,
}
// Retrieve enum metadata with: proto3.getEnumType(Currency)
proto3.util.setEnumType(Currency, "common.v1.Currency", [
  { no: 0, name: "CURRENCY_UNSPECIFIED" },
  { no: 1, name: "CURRENCY_USD" },
  { no: 2, name: "CURRENCY_AED" },
  { no: 3, name: "CURRENCY_AFN" },
  { no: 4, name: "CURRENCY_ALL" },
  { no: 5, name: "CURRENCY_AMD" },
  { no: 6, name: "CURRENCY_ANG" },
  { no: 7, name: "CURRENCY_AOA" },
  { no: 8, name: "CURRENCY_ARS" },
  { no: 9, name: "CURRENCY_AUD" },
  { no: 10, name: "CURRENCY_AWG" },
  { no: 11, name: "CURRENCY_AZN" },
  { no: 12, name: "CURRENCY_BAM" },
  { no: 13, name: "CURRENCY_BBD" },
  { no: 14, name: "CURRENCY_BDT" },
  { no: 15, name: "CURRENCY_BGN" },
  { no: 16, name: "CURRENCY_BIF" },
  { no: 17, name: "CURRENCY_BMD" },
  { no: 18, name: "CURRENCY_BND" },
  { no: 19, name: "CURRENCY_BOB" },
  { no: 20, name: "CURRENCY_BRL" },
  { no: 21, name: "CURRENCY_BSD" },
  { no: 22, name: "CURRENCY_BWP" },
  { no: 23, name: "CURRENCY_BYN" },
  { no: 24, name: "CURRENCY_BZD" },
  { no: 25, name: "CURRENCY_CAD" },
  { no: 26, name: "CURRENCY_CDF" },
  { no: 27, name: "CURRENCY_CHF" },
  { no: 28, name: "CURRENCY_CLP" },
  { no: 29, name: "CURRENCY_CNY" },
  { no: 30, name: "CURRENCY_COP" },
  { no: 31, name: "CURRENCY_CRC" },
  { no: 32, name: "CURRENCY_CVE" },
  { no: 33, name: "CURRENCY_CZK" },
  { no: 34, name: "CURRENCY_DJF" },
  { no: 35, name: "CURRENCY_DKK" },
  { no: 36, name: "CURRENCY_DOP" },
  { no: 37, name: "CURRENCY_DZD" },
  { no: 38, name: "CURRENCY_EGP" },
  { no: 39, name: "CURRENCY_ETB" },
  { no: 40, name: "CURRENCY_EUR" },
  { no: 41, name: "CURRENCY_FJD" },
  { no: 42, name: "CURRENCY_FKP" },
  { no: 43, name: "CURRENCY_GBP" },
  { no: 44, name: "CURRENCY_GEL" },
  { no: 45, name: "CURRENCY_GIP" },
  { no: 46, name: "CURRENCY_GMD" },
  { no: 47, name: "CURRENCY_GNF" },
  { no: 48, name: "CURRENCY_GTQ" },
  { no: 49, name: "CURRENCY_GYD" },
  { no: 50, name: "CURRENCY_HKD" },
  { no: 51, name: "CURRENCY_HNL" },
  { no: 52, name: "CURRENCY_HRK" },
  { no: 53, name: "CURRENCY_HTG" },
  { no: 54, name: "CURRENCY_HUF" },
  { no: 55, name: "CURRENCY_IDR" },
  { no: 56, name: "CURRENCY_ILS" },
  { no: 57, name: "CURRENCY_INR" },
  { no: 58, name: "CURRENCY_ISK" },
  { no: 59, name: "CURRENCY_JMD" },
  { no: 60, name: "CURRENCY_JPY" },
  { no: 61, name: "CURRENCY_KES" },
  { no: 62, name: "CURRENCY_KGS" },
  { no: 63, name: "CURRENCY_KHR" },
  { no: 64, name: "CURRENCY_KMF" },
  { no: 65, name: "CURRENCY_KRW" },
  { no: 66, name: "CURRENCY_KYD" },
  { no: 67, name: "CURRENCY_KZT" },
  { no: 68, name: "CURRENCY_LAK" },
  { no: 69, name: "CURRENCY_LBP" },
  { no: 70, name: "CURRENCY_LKR" },
  { no: 71, name: "CURRENCY_LRD" },
  { no: 72, name: "CURRENCY_LSL" },
  { no: 73, name: "CURRENCY_MAD" },
  { no: 74, name: "CURRENCY_MDL" },
  { no: 75, name: "CURRENCY_MGA" },
  { no: 76, name: "CURRENCY_MKD" },
  { no: 77, name: "CURRENCY_MMK" },
  { no: 78, name: "CURRENCY_MNT" },
  { no: 79, name: "CURRENCY_MOP" },
  { no: 80, name: "CURRENCY_MRO" },
  { no: 81, name: "CURRENCY_MUR" },
  { no: 82, name: "CURRENCY_MVR" },
  { no: 83, name: "CURRENCY_MWK" },
  { no: 84, name: "CURRENCY_MXN" },
  { no: 85, name: "CURRENCY_MYR" },
  { no: 86, name: "CURRENCY_MZN" },
  { no: 87, name: "CURRENCY_NAD" },
  { no: 88, name: "CURRENCY_NGN" },
  { no: 89, name: "CURRENCY_NIO" },
  { no: 90, name: "CURRENCY_NOK" },
  { no: 91, name: "CURRENCY_NPR" },
  { no: 92, name: "CURRENCY_NZD" },
  { no: 93, name: "CURRENCY_PAB" },
  { no: 94, name: "CURRENCY_PEN" },
  { no: 95, name: "CURRENCY_PGK" },
  { no: 96, name: "CURRENCY_PHP" },
  { no: 97, name: "CURRENCY_PKR" },
  { no: 98, name: "CURRENCY_PLN" },
  { no: 99, name: "CURRENCY_PYG" },
  { no: 100, name: "CURRENCY_QAR" },
  { no: 101, name: "CURRENCY_RON" },
  { no: 102, name: "CURRENCY_RSD" },
  { no: 103, name: "CURRENCY_RUB" },
  { no: 104, name: "CURRENCY_RWF" },
  { no: 105, name: "CURRENCY_SAR" },
  { no: 106, name: "CURRENCY_SBD" },
  { no: 107, name: "CURRENCY_SCR" },
  { no: 108, name: "CURRENCY_SEK" },
  { no: 109, name: "CURRENCY_SGD" },
  { no: 110, name: "CURRENCY_SHP" },
  { no: 111, name: "CURRENCY_SLE" },
  { no: 112, name: "CURRENCY_SLL" },
  { no: 113, name: "CURRENCY_SOS" },
  { no: 114, name: "CURRENCY_SRD" },
  { no: 115, name: "CURRENCY_STD" },
  { no: 116, name: "CURRENCY_SZL" },
  { no: 117, name: "CURRENCY_THB" },
  { no: 118, name: "CURRENCY_TJS" },
  { no: 119, name: "CURRENCY_TOP" },
  { no: 120, name: "CURRENCY_TRY" },
  { no: 121, name: "CURRENCY_TTD" },
  { no: 122, name: "CURRENCY_TWD" },
  { no: 123, name: "CURRENCY_TZS" },
  { no: 124, name: "CURRENCY_UAH" },
  { no: 125, name: "CURRENCY_UGX" },
  { no: 126, name: "CURRENCY_UYU" },
  { no: 127, name: "CURRENCY_UZS" },
  { no: 128, name: "CURRENCY_VND" },
  { no: 129, name: "CURRENCY_VUV" },
  { no: 130, name: "CURRENCY_WST" },
  { no: 131, name: "CURRENCY_XAF" },
  { no: 132, name: "CURRENCY_XCD" },
  { no: 133, name: "CURRENCY_XOF" },
  { no: 134, name: "CURRENCY_XPF" },
  { no: 135, name: "CURRENCY_YER" },
  { no: 136, name: "CURRENCY_ZAR" },
  { no: 137, name: "CURRENCY_ZMW" },
]);

