import { DateTime } from '$lib/gen/google/type/datetime_pb'
import { Date as GoogleDate } from '$lib/gen/google/type/date_pb'
import { formatISO } from 'date-fns'

export default class DateWithhoutZone extends GoogleDate {
  toDate(): Date {
    const date = new Date()
    date.setFullYear(this.year, this.month - 1, this.day)
    return date
  }

  static fromDate(input: Date): DateWithhoutZone {
    return new DateWithhoutZone({
      year: input.getFullYear(),
      month: input.getMonth() + 1,
      day: input.getDate(),
    })
  }
}

export function dateToISO(date: GoogleDate) {
  return formatISO(new DateWithhoutZone(date).toDate(), { representation: 'date' })
}

// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export function fromPBDate(date: GoogleDate | DateTime): Date {
  const d = new Date()
  d.setFullYear(date.year, date.month - 1, date.day)
  if (date instanceof DateTime) {
    d.setHours(date.hours, date.minutes, date.seconds, date.nanos / 1000000)
  }
  return d
}

export function toPBDate(date: Date): DateWithhoutZone {
  // date = zonedTimeToUtc(date, timezone)
  return DateWithhoutZone.fromDate(date)
}
